<template>
  <div :class="personClass()" class="person">
    <div class="status-circle"></div>
  </div>
</template>

<script>
export default {
  name: 'HomePerson',
  props: {
    person: {
      type: Object,
      required: true,
    },
  },
  methods: {
    personClass() {
      const bgClass = this.person.pic;
      let statusClass = 'status-in';
      if (this.person.status === 'out') statusClass = 'status-out';
      let classStr = `${bgClass} ${statusClass}`;
      if (this.person.hideOnMobile) classStr += ' hide-on-mobile';
      return classStr;
    },
  },
};
</script>

<style scoped>
.person {
  width: 4em;
  height: 4em;
  background-color: #ffffff;
  color: #1f1f1f;
  margin: 3px;
  box-shadow: 0 0 0.9em 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #cecece;
  border-radius: 0.3em;
  text-align: center;
  position: relative;
  background-image: url('~@/assets/1.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
}

.hide-on-mobile {
  display: none;
}

.pic1 {
  background-image: url('~@/assets/1.jpg');
}

.pic2 {
  background-image: url('~@/assets/2.jpg');
}

.pic3 {
  background-image: url('~@/assets/3.jpg');
}

.pic4 {
  background-image: url('~@/assets/4.jpg');
}

.pic5 {
  background-image: url('~@/assets/5.jpg');
}

.pic6 {
  background-image: url('~@/assets/6.jpg');
}

.pic7 {
  background-image: url('~@/assets/15.jpg');
}

.pic8 {
  background-image: url('~@/assets/8.jpg');
}

.pic9 {
  background-image: url('~@/assets/9.jpg');
}

.pic10 {
  background-image: url('~@/assets/10.jpg');
}

.pic11 {
  background-image: url('~@/assets/14.jpg');
}

.pic12 {
  background-image: url('~@/assets/12.jpg');
}

.status-circle {
  position: absolute;
  top: 0.2em;
  left: 0.2em;
  width: 10%;
  height: 10%;
  border-radius: 50%;
  border: 1px solid #4e4e4e;
  background-color: #9f9f9f;
}

.status-in .status-circle {
  background-color: #32cd32;
}

.status-out .status-circle {
  background-color: #9f9f9f;
}

@media only screen and (min-width: 768px) {
  .person {
    width: 9em;
    height: 9em;
  }

  .hide-on-mobile {
    display: block;
  }
}
</style>
