<template>
  <div class="header-bar">
    <router-link class="left-holder" to="/">
      <div class="logo-top"></div>
      <div class="name-top">Tuumik</div>
    </router-link>
    <div class="regular-menu">
      <div class="site-link" @click="scrollTo('features')">FEATURES</div>
      <div class="site-link" @click="scrollTo('use-cases')">USE CASES</div>
      <div class="site-link" @click="scrollTo('pricing')">PRICING</div>
      <div class="site-link" @click="scrollTo('demo')">DEMO</div>
      <router-link class="btn-start" to="/start">GET STARTED</router-link>
    </div>
    <div class="menu-opener" @click="showMenu = true"></div>
    <div :class="{ 'mobile-menu-on': showMenu }" class="mobile-menu">
      <div class="menu-closer" @click="showMenu = false"></div>
      <div class="m-site-link m-b" @click="scrollTo('features')">FEATURES</div>
      <div class="m-site-link" @click="scrollTo('use-cases')">USE CASES</div>
      <div class="m-site-link" @click="scrollTo('pricing')">PRICING</div>
      <div class="m-site-link" @click="scrollTo('demo')">DEMO</div>
      <router-link class="m-site-link m-start" to="/start" @click.native="showMenu = false">GET STARTED</router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HeaderBar',
  data() {
    return {
      showMenu: false,
    };
  },
  methods: {
    async scrollTo(target) {
      this.showMenu = false;
      if (this.$route.path === '/') {
        const elem = document.getElementById(target);
        if (elem) elem.scrollIntoView({ behavior: 'smooth' });
      } else {
        this.$router.push('/');
        await this.$nextTick();
        const elem = document.getElementById(target);
        if (elem) elem.scrollIntoView({ behavior: 'instant' });
      }
    },
  },
};
</script>

<style scoped>
.header-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #ffffff;
  border-bottom: 1px solid #e7e7e7;
  color: #ffffff;
  height: 3em;
  padding: 0 1em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  box-shadow: 0 0 0.9em 0 rgba(0, 0, 0, 0.07);
}

.left-holder {
  height: 2em;
  margin: 0 auto 0 0;
  display: flex;
  align-items: center;
}

.regular-menu {
  display: none;
}

.menu-opener {
  width: 3em;
  height: 2em;
  margin: 0;
  background-image: url('~@/assets/menu.svg');
  background-repeat: no-repeat;
  background-size: 60%;
  background-position: right center;
}

.mobile-menu {
  position: fixed;
  top: 0.5em;
  bottom: 0.5em;
  left: 0.5em;
  right: 0.5em;
  padding: 4em 1em 1em 1em;
  background-color: #ffffff;
  border: 1px solid #cecece;
  border-radius: 0.4em;
  overflow-y: auto;
  box-shadow: 0 0 0.9em 0 rgba(0, 0, 0, 0.07);
  transform: translateX(120vw);
  transition: transform 300ms ease;
}

.mobile-menu-on {
  transform: translateX(0);
}

.menu-closer {
  position: absolute;
  top: 1em;
  right: 1em;
  width: 2em;
  height: 2em;
  margin: 0;
  background-image: url('~@/assets/close.svg');
  background-repeat: no-repeat;
  background-size: 90%;
  background-position: center center;
}

.m-site-link {
  display: block;
  margin: 0;
  padding: 1.5em 0;
  font-size: 0.8em;
  background-color: #ffffff;
  color: #1f1f1f;
  border-bottom: 1px solid #e9e9e9;
  cursor: pointer;
}

.m-b {
  border-top: 1px solid #e9e9e9;
}

.m-start {
  font-weight: 600;
  margin-top: 4em;
  background-color: #00ab6a;
  color: #ffffff;
  border: 1px solid #00ab6a;
  border-radius: 0.3em;
}

.logo-top {
  width: 2em;
  height: 2em;
  margin: 0;
  background-image: url('~@/assets/logo-green.svg');
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
}

.name-top {
  color: #1f1f1f;
  font-weight: 600;
  margin: 0 0 0 1em;
  user-select: none;
}

.site-link {
  background-color: #ffffff;
  color: #1f1f1f;
  padding: 0.6em 0.6em;
  margin: 0 0.7em;
  border: 1px solid #ffffff;
  border-radius: 0.3em;
  user-select: none;
  display: inline-block;
  transition: background-color 250ms ease-out;
  font-size: 0.9em;
  cursor: pointer;
}

.site-link:hover {
  background-color: #ffffff;
  color: #3f3f3f;
  border: 1px solid #e9e9e9;
}

.btn-start {
  background-color: #00ab6a;
  color: #ffffff;
  border: 1px solid #00ab6a;
  padding: 0.6em 0.6em;
  margin: 0 0.7em;
  border-radius: 0.3em;
  font-weight: 600;
  user-select: none;
  display: inline-block;
  transition: background-color 250ms ease-out;
  font-size: 0.9em;
}

.btn-start:hover {
  background-color: #02c97c;
  border: 1px solid #02c97c;
}

@media only screen and (min-width: 768px) {
  .regular-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .mobile-menu {
    display: none;
  }

  .menu-opener {
    display: none;
  }
}
</style>
