<template>
  <div class="pricing-holder">
    <div class="target-holder"><div id="pricing" class="target"></div></div>
    <h2>Pricing</h2>
    <div class="plans-holder">
      <div class="plan">
        <div class="plan-stripe stripe-free"></div>
        <div class="plan-title">FREE</div>
        <div class="plan-price">$0</div>
        <div class="plan-unit">per user per month</div>
        <div class="plan-explanation">for up to 10 users</div>
        <div class="plan-specs">
          <div class="spec">All features available</div>
          <div class="spec">Self-hosted export functions</div>
          <div class="spec spec-off">Our export functions</div>
        </div>
        <router-link class="btn-start" to="/start">GET STARTED</router-link>
      </div>
      <div class="plan">
        <div class="plan-stripe stripe-basic"></div>
        <div class="plan-title">BASIC ANNUAL</div>
        <div class="plan-price">$5</div>
        <div class="plan-unit">per user per month</div>
        <div class="plan-explanation">billed annually</div>
        <div class="plan-specs">
          <div class="spec">All features available</div>
          <div class="spec">Self-hosted export functions</div>
          <div class="spec">Our export functions</div>
        </div>
        <router-link class="btn-start" to="/start">GET STARTED</router-link>
      </div>
      <div class="plan">
        <div class="plan-stripe stripe-standard"></div>
        <div class="plan-title">BASIC MONTHLY</div>
        <div class="plan-price">$6</div>
        <div class="plan-unit">per user per month</div>
        <div class="plan-explanation">billed monthly</div>
        <div class="plan-specs">
          <div class="spec">All features available</div>
          <div class="spec">Self-hosted export functions</div>
          <div class="spec">Our export functions</div>
        </div>
        <router-link class="btn-start" to="/start">GET STARTED</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomePricing',
};
</script>

<style scoped>
.pricing-holder {
  margin: 11em 0 9em 0;
}

.target-holder {
  position: relative;
}

.target {
  position: absolute;
  top: -7em;
}

.plans-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.plan {
  width: 16em;
  background-color: #ffffff;
  color: #1f1f1f;
  padding: 1.5em 1em 1em 1em;
  margin: 0 0.6em 4em 0.6em;
  box-shadow: 0 0 0.9em 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #cecece;
  border-radius: 0.3em;
  text-align: center;
  position: relative;
}

.plan-stripe {
  position: absolute;
  top: -1px;
  left: -1px;
  right: -1px;
  height: 10px;
  border-radius: 0.3em 0.3em 0 0;
}

.stripe-free {
  background-color: #6f6f6f;
}

.stripe-basic,
.stripe-standard,
.stripe-plus {
  background-color: #00ab6a;
}

.plan-title {
  font-size: 1.4em;
  font-weight: 300;
  margin: 0 0 1em 0;
}

.plan-price {
  font-size: 3.4em;
  font-weight: 600;
}

.plan-unit {
  font-weight: 600;
}

.plan-explanation {
  margin: 0.5em 0 0 0;
}

.plan-specs {
  margin: 2em 0 1em 0;
}

.spec {
  padding: 6px 0;
  border-bottom: 1px solid #e2e2e2;
}

.spec:first-child {
  border-top: 1px solid #e2e2e2;
}

.spec-off {
  opacity: 0.4;
}

.btn-start {
  background-color: #00ab6a;
  color: #f9f9f9;
  padding: 0.7em 2em;
  margin: 2em auto;
  border: 1px solid #00ab6a;
  border-radius: 0.3em;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  display: inline-block;
  transition: background-color 250ms ease-out;
}

.btn-start:hover,
.btn-start:active,
.btn-start:focus {
  background-color: #02c97c;
  color: #ffffff;
  border: 1px solid #02c97c;
}

.btn-start div {
  font-size: 0.7em;
  font-weight: 400;
  color: #ffffff;
}

@media only screen and (min-width: 768px) {
  .spec-off {
    display: block;
    opacity: 0.4;
    user-select: none;
  }

  .btn {
    font-size: 0.8em;
  }
}
</style>
