<template>
  <div id="app">
    <HeaderBar />
    <router-view id="content-holder" />
    <FooterBar />
  </div>
</template>

<script>
import HeaderBar from '@/components/HeaderBar.vue';
import FooterBar from '@/components/FooterBar.vue';

export default {
  name: 'Home',
  components: {
    HeaderBar,
    FooterBar,
  },
};
</script>

<style>
body {
  font-family: 'Source Sans Pro', Helvetica, Arial, Verdana, sans-serif;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  margin: 0;
  color: #1f1f1f;
  background-color: #2f2f2f;
}

h1 {
  font-size: 2em;
  font-weight: 600;
}

h2 {
  font-size: 1.5em;
  font-weight: 600;
}

#content-holder {
  background-color: #ffffff;
}

a:link,
a:visited {
  text-decoration: none;
  color: #056641;
}

a:hover,
a:active {
  color: #03915a;
}

.rlink,
.rlink:hover,
.rlink:visited,
.rlink:active {
  text-decoration: underline;
  color: #ffffff;
}

.spinner {
  border: 3px dashed #00ab6a;
  border-bottom: 1px dashed transparent;
  border-radius: 50%;
  animation: spinner 3000ms 0ms infinite forwards linear;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
