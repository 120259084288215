<template>
  <div class="home">
    <h1 class="intro-text">Modern Team Management Software for Hybrid Work</h1>
    <div class="intro-text2">
      Tuumik gives managers insight into what their teams are doing and team members a better way to separate personal time from work. Instead of limiting remote work make it more
      transparent.
    </div>
    <div class="intro-text2">Tuumik can be self-hosted and is source-available so you have full control over your data.</div>
    <HomePeople />
    <div class="btn-start" @click="scrollTo('demo')">LIVE DEMO</div>
    <router-link class="btn-start" to="/start">GET STARTED</router-link>
    <HomeGallery />
    <HomeUses />
    <HomePricing />
    <HomeDemo />
  </div>
</template>

<script>
import HomePeople from '@/components/HomePeople.vue';
import HomeGallery from '@/components/HomeGallery.vue';
import HomeUses from '@/components/HomeUses.vue';
import HomePricing from '@/components/HomePricing.vue';
import HomeDemo from '@/components/HomeDemo.vue';

export default {
  name: 'Home',
  components: {
    HomePeople,
    HomeGallery,
    HomeUses,
    HomePricing,
    HomeDemo,
  },
  methods: {
    async scrollTo(target) {
      this.showMenu = false;
      if (this.$route.path === '/') {
        const elem = document.getElementById(target);
        if (elem) elem.scrollIntoView({ behavior: 'smooth' });
      } else {
        this.$router.push('/');
        await this.$nextTick();
        const elem = document.getElementById(target);
        if (elem) elem.scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>

<style scoped>
.home {
  padding: 16vh 0 1em 0;
}

.intro-text {
  font-size: 2em;
  font-weight: 300;
  margin: 0 1.5em;
  text-align: center;
}

.intro-text2 {
  font-size: 1.2em;
  font-weight: 400;
  padding: 1.5em 1.5em 0 1.5em;
  text-align: center;
  line-height: 1.5em;
}

.btn-start {
  background-color: #00ab6a;
  color: #ffffff;
  padding: 1em 0;
  width: 12em;
  margin: 1em 1em;
  border: 1px solid #00ab6a;
  border-radius: 0.3em;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  display: inline-block;
  transition: background-color 250ms ease-out;
}

.btn-start:hover,
.btn-start:active,
.btn-start:focus {
  background-color: #02c97c;
  color: #ffffff;
  border: 1px solid #02c97c;
}

.btn-start div {
  font-size: 0.7em;
  font-weight: 400;
  color: #1f1f1f;
}

@media only screen and (min-width: 768px) {
  .intro-text {
    font-size: 2.7em;
    margin: 0 2em;
  }

  .intro-text2 {
    font-size: 1.4em;
    margin: 0 auto;
    max-width: 920px;
  }
}
</style>
