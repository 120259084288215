<template>
  <div class="uses-holder">
    <div class="target-holder"><div id="use-cases" class="target"></div></div>
    <h2>Use Cases</h2>
    <div class="boxes-holder">
      <div class="box-holder">
        <h2 class="box-heading">Remote and Hybrid Work</h2>
        <div class="box-subheading">Alternative for return to office</div>
        <div class="box-desc">
          Tuumik makes remote and hybrid work better and more transparent. Instead of requiring a return to office, make working remotely more efficient for both managers and team
          members.
        </div>
      </div>
      <div class="box-holder">
        <h2 class="box-heading">Law Firms</h2>
        <div class="box-subheading">And other time based services</div>
        <div class="box-desc">
          Tuumik was originally built for law firms. To make it easy for team members to track their time. And to provide partners an up to date overview of everyone's workload and
          the ability to easily collect timesheet data for billing. In this role Tuumik is suitable for any business that bills based on time.
        </div>
      </div>
      <div class="box-holder">
        <h2 class="box-heading">Custom Development</h2>
        <div class="box-subheading">Faster than starting from zero</div>
        <div class="box-desc">
          Tuumik is built on widely used open source technologies (MongoDB, Node, Meteor, Vue) and its own source code is also publicly available. This makes Tuumik a good platform
          for custom development work. We support such projects and can help you find the right developers for the job, if necessary.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HomeUses',
};
</script>

<style scoped>
.uses-holder {
  margin: 9em 0 9em 0;
}

.target-holder {
  position: relative;
}

.target {
  position: absolute;
  top: -7em;
}

.boxes-holder {
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  text-align: left;
}

.box-holder {
  width: 90%;
  border: 1px solid #cecece;
  border-radius: 18px;
  padding: 1em;
  margin: 0.5em 0.5em 1em 0.5em;
  flex-grow: 1;
  line-height: 1.7em;
  box-shadow: 0 0 0.9em 0 rgba(0, 0, 0, 0.07);
}

.box-heading {
  font-size: 1.2em;
  margin: 0;
  color: #00ab6a;
}

.box-subheading {
  margin: 0;
  font-weight: 600;
}

.box-desc {
  margin: 1em 0 0 0;
}

@media only screen and (min-width: 768px) {
  .box-holder {
    width: 25%;
  }
}
</style>
