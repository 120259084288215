<template>
  <div class="gallery-holder">
    <!-- eslint-disable -->
    <div class="target-holder"><div id="features" class="target"></div></div>
    <h2>Features</h2>
    <div class="txt">
      <span>Track time on any device. Either install as separate app (Windows, macOS, Linux, iOS, Android) or don't install anything and simply use from a web browser</span>
    </div>
    <div class="pic s1"></div>
    <div class="txt txt-spacer">
      <span>Flexible queries to analyze tracked data</span>
    </div>
    <div class="pic s2"></div>
    <div class="txt txt-spacer">
      <span>
        Output tracked data into spreadsheets for reporting or billing
      </span>
    </div>
    <div class="pic s3"></div>
    <div class="txt txt-spacer">
      <span>Team monitor. See what everyone is working on in real time</span>
    </div>
    <div class="pic s4"></div>
    <div class="txt txt-spacer">
      <span>In/out board. Avoid burnout by enabling remote employees to establish a clearer border between work and personal time. Make communication easier for everyone</span>
    </div>
    <div class="pic s5"></div>
    <!-- eslint-enable -->
  </div>
</template>

<script>
export default {
  name: 'HomeGallery',
};
</script>

<style scoped>
.gallery-holder {
  margin: 6em 0 0 0;
}

.target-holder {
  position: relative;
}

.target {
  position: absolute;
  top: -7em;
}

.pic {
  width: 95%;
  max-width: 90em;
  padding-top: 45%; /* maintains aspect ratio based on width */
  background-color: #ffffff;
  color: #1f1f1f;
  margin: 1em auto 2em auto;
  box-shadow: 0 0 0.9em 0 rgba(0, 0, 0, 0.07);
  border: 1px solid #cecece;
  border-radius: 0.3em;
  text-align: center;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center top;
}

.s1 {
  background-image: url('~@/assets/s1.png');
}

.s2 {
  background-image: url('~@/assets/s2.png');
}

.s3 {
  background-image: url('~@/assets/s3.png');
}

.s4 {
  background-image: url('~@/assets/s4.png');
}

.s5 {
  background-image: url('~@/assets/s5.jpg');
}

.txt {
  margin: 1em auto 0 auto;
  width: 95%;
  max-width: 50em;
}

.txt span {
  font-size: 1.2em;
  font-weight: 600;
  color: #1f1f1f;
  padding: 0.2em 0.3em;
  line-height: 1.5em;
}

.txt-spacer {
  margin-top: 6em;
}

@media only screen and (min-width: 768px) {
  .left-holder {
    display: flex;
    align-items: center;
  }

  .pic {
    width: 80%;
    max-width: 90em;
    padding-top: 30%;
  }

  .txt {
    width: 80%;
    max-width: 50em;
  }
}
</style>
