<template>
  <div class="demo-holder">
    <div class="target-holder"><div id="demo" class="target"></div></div>
    <h2>Demo</h2>
    <div class="demo-text">Try out Tuumik immediately in a temporary environment with sample data at demo.tuumik.com</div>
    <a href="https://demo.tuumik.com/start-demo" target="_blank" class="btn-demo">OPEN LIVE DEMO</a>
  </div>
</template>

<script>
export default {
  name: 'HomeDemo',
};
</script>

<style scoped>
.demo-holder {
  margin: 9em 0 9em 0;
}

.target-holder {
  position: relative;
}

.target {
  position: absolute;
  top: -9em;
}

.demo-text {
  font-size: 1.2em;
  padding: 0 1.5em;
  text-align: center;
  line-height: 1.5em;
}

.btn-demo {
  background-color: #00ab6a;
  color: #f9f9f9;
  padding: 0.7em 2em;
  margin: 2em auto;
  border: 1px solid #00ab6a;
  border-radius: 0.3em;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  display: inline-block;
  transition: background-color 250ms ease-out;
}

.btn-demo:hover,
.btn-demo:active,
.btn-demo:focus {
  background-color: #02c97c;
  color: #ffffff;
  border: 1px solid #02c97c;
}

@media only screen and (min-width: 768px) {
  .left-holder {
    display: flex;
    align-items: center;
  }

  .btn {
    font-size: 0.8em;
  }
}
</style>
