<template>
  <div class="start-view">
    <div class="content-holder">
      <h1>Get Started</h1>
      <div>
        There are two general options to set up Tuumik for your organization. You can either self-host and manage the infrastructure yourself. Or you can choose to have a
        professional service provider set up and manage the infrastructure for you. If you are unsure which option to choose, we recommend choosing managed hosting by an
        experienced service provider.
      </div>
      <h2>Managed hosting</h2>
      <div>
        Tuumik is built on widely used open source technologies (MongoDB and Node). This means that most companies that provide hosting services are able to host Tuumik for you. We
        recommend
        <a href="https://meteor.com" target="_blank">meteor.com</a>
        or
        <a href="https://zcloud.ws" target="_blank">zcloud.ws</a>. These companies have a lot of experience with hosting apps similar to Tuumik. <br /><br />
        The cost of managed hosting depends primarily on how many users the servers need to handle. You can see pricing on each service provider's website.
        <br /><br />
        <div>Feel free to contact support@tuumik.com if you need help choosing a service provider.</div>
      </div>
      <h2>Self-hosting</h2>
      <div>
        Start by downloading Tuumik's latest release from Github and following the setup instructions provided in the release.
      </div>
      <a href="https://github.com/tuumikapp/tuumik" target="_blank" class="btn-repo">OPEN GITHUB</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Start',
  mounted() {
    document.body.scrollIntoView({ behavior: 'instant' });
  },
};
</script>

<style scoped>
.start-view {
  padding: 12vh 1em 12vh 1em;
}

.content-holder {
  margin: 0 auto;
  max-width: 920px;
  text-align: left;
  line-height: 1.8em;
}

.btn-repo {
  background-color: #00ab6a;
  color: #f9f9f9;
  padding: 0.7em 2em;
  margin: 2em auto;
  border: 1px solid #00ab6a;
  border-radius: 0.3em;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  display: inline-block;
  transition: background-color 250ms ease-out;
}

.btn-repo:hover,
.btn-repo:active,
.btn-repo:focus {
  background-color: #02c97c;
  color: #ffffff;
  border: 1px solid #02c97c;
}

@media only screen and (min-width: 768px) {
  .start-view {
    padding: 18vh 2em 22vh 2em;
  }
}
</style>
