<template>
  <div class="people-holder">
    <HomePerson v-for="person in people" :key="person.name" :person="person" />
  </div>
</template>

<script>
import HomePerson from '@/components/HomePerson.vue';

export default {
  name: 'HomePeople',
  components: {
    HomePerson,
  },
  data() {
    return {
      people: [
        { name: 'Name1', pic: 'pic1', status: 'in', hideOnMobile: false },
        { name: 'Name2', pic: 'pic2', status: 'out', hideOnMobile: false },
        { name: 'Name3', pic: 'pic3', status: 'in', hideOnMobile: false },
        { name: 'Name4', pic: 'pic4', status: 'out', hideOnMobile: false },
        { name: 'Name5', pic: 'pic5', status: 'in', hideOnMobile: false },
        { name: 'Name6', pic: 'pic6', status: 'out', hideOnMobile: false },
        { name: 'Name7', pic: 'pic7', status: 'out', hideOnMobile: false },
        { name: 'Name8', pic: 'pic8', status: 'in', hideOnMobile: false },
        { name: 'Name9', pic: 'pic9', status: 'in', hideOnMobile: false },
        { name: 'Name10', pic: 'pic10', status: 'in', hideOnMobile: false },
        { name: 'Name11', pic: 'pic11', status: 'in', hideOnMobile: false },
        { name: 'Name12', pic: 'pic12', status: 'in', hideOnMobile: false },
      ],
    };
  },
};
</script>

<style scoped>
.people-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 60em;
  margin: 3em 2em 3em 2em;
}

@media only screen and (min-width: 768px) {
  .people-holder {
    margin: 3em auto 3em auto;
  }
}
</style>
